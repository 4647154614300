.othello-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  padding-top: 5px;
  margin: auto;
}

.score {
  width: 100%;
  max-width: 600px;
  align-self: flex-start;
  height: 90px;
  display: flex;
  justify-content: space-between;
  /* padding: 10px 0; */
}

.passed {
  animation: shake 40ms ease-in-out 20 alternate;
  visibility: hidden;
}

@keyframes shake {
  from {
    visibility: visible;
    transform: rotateZ(-5deg);
  }

  to {
    visibility: visible;
    transform: rotateZ(5deg);
  }
}

.points b {
  display: none;
}

.passed b {
  display: block;
  font-size: 2em;
  font-weight: 900;
  color: rgba(48, 116, 233, 0.85);
  text-shadow: 5px 5px rgba(124, 124, 124, 0.29);
  -webkit-text-stroke: #2e3df3;
  -webkit-text-stroke-width: 2px;
}

.points {
  /* height: 100%; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
}

.othello-utils {
  display: flex;
}

.game-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.outlined {
  --outline: #888;
  border: 3px solid var(--outline);
  filter: drop-shadow(3px 3px 0 var(--dshadow));
}

.humanPlayer {
  --outline: #1e4cf2;
}

.points span {
  font-size: 2.2em;
  font-weight: 600;
  color: var(--tcolor);
  margin: 0 30px;
  text-align: center;
  /* line-height: 50px; */
}

.demopiece {
  background: var(--blackColor);
  height: 50px;
  width: 50px;
  margin: 0 10px;
  border-radius: 50%;
  box-shadow: 3px 3px 8px var(--pShadow);
}

.gamescreen {
  display: flex;
  align-self: flex-start;
  width: max-content;
}

.oth-options {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.playoption {
  padding: 5px;
  margin-bottom: 20px;
  font-weight: bolder;
  color: var(--tcolor);
  font-size: 0.84em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playoption .svgcontainer {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  background: var(--svgBack);
  margin-bottom: 4px;
  cursor: pointer;
  display: grid;
  place-items: center;
}

.board {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  width: 600px;
  height: 600px;
  border: 10px solid var(--boardRim);
  background: var(--boardRim);
}

.winnercontainer {
  position: absolute;
  width: 100%;
  height: 600px;
  display: grid;
  place-items: center;
}

.winnercard {
  width: 200px;
  height: 120px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  vertical-align: middle;

  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(74, 74, 74, 0.61);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.winnercard .result {
  font-size: 1.5em;
  font-weight: bolder;
  text-align: center;
  margin: 20px;
  color: #eeeeee;
  -webkit-text-stroke-width: 0.4px;
  -webkit-text-stroke-color: #000;
}

.winnercard .again {
  font-size: 1em;
  font-weight: 600;
  color: #cfcfcf;
  cursor: pointer;
}

.box {
  /* margin: -0.1px; */
  /* margin-bottom: -0.2px; */
  position: relative;
  background: #0eb56a;
  border: 1px solid #1c242e;
  display: grid;
  place-items: center;
}

.last {
  position: absolute;
  width: 25%;
  height: 25%;
  background: rgba(254, 29, 29, 0.9);
  border-radius: 50%;
}

.avail {
  position: relative;
  width: 15%;
  height: 15%;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 4px rgba(101, 101, 101, 0.51);
  border-radius: 50%;
}

.avail-black {
  background: rgba(54, 54, 54, 0.5);
}

.piece {
  position: relative;
  width: 90%;
  height: 90%;
  transform-style: preserve-3d;
  transition: all 0.2s ease-in-out;
}

.piece>* {
  position: absolute;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.55);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  backface-visibility: hidden;
}

.white {
  background: #fefefe;
}

.black {
  background: #222;
  transform: rotateY(180deg);
}

.back {
  transform: perspective(1000px) rotateY(180deg);
}
